.contact-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-content: space-between;
    width: 50%;
    max-width: 500px;
    min-width: 300px;
}

.contact-container form {
    justify-content: space-between;
    margin: 0;
}

.contact-container form div {
    padding: 10px 0px 10px 0px;
}

.required {
    color: red;
}

.form-message textarea{
    resize: none;
}

.contact-container footer {
    text-align: center;
}

