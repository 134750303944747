.pokemonApp-Container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    z-index: 0;
    background-color:#111418;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: center; 
}

.PokemonLogo {
    margin: 0 auto;
    width: 45%;
    max-width: 750px;
    min-width: 310px;
}

#inputText {
    margin: 0px auto 20px auto;
    color: green;
    text-shadow: 3px 3px 2px black;
    font-size: calc(10px + 2vw);
}

#pokeForm {
    width: 30%;
    min-width: 200px;
    max-width: 500px;
    margin: 0px auto 20px auto;
    text-align: center;
    border: 5px solid #4388e2;
    border-radius: 10px;
    background-color: #b4a734;
    
}

#pokeForm input {
    display: inline;
    width: 70%;
    font-size: calc(6px + 1vw);
    letter-spacing: 1px;
    padding: 10px;
    border: none;
    background-color: #eee48c;
    border-right: 0;
    border-radius: 0;
    outline: none;
}

#pokeForm button {
    display: inline;
    padding: 2%;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 30%;
    min-width: 40px;
    border: none;
    border-left: 0;
    background-color: #e7d532;
    font-size: calc(6px + 1vw);
    font-weight: bold;
}

#pokeForm button:hover {
    background-color: #e9e9e9;
    cursor: pointer;
}


#pokeData {
    position: relative;
    display: block;
    margin: auto;
    width: 50%;
    min-width: 300px;
    background-color:#111418;
    padding-bottom: 50px;
}

#pokeData img {
    margin: 0 auto 0 auto;
    height: 170px;
    padding: 0px;
    object-fit: cover;
}

#pokeData h2 {
    margin: auto;
    display: inline;
    color: yellow;
    text-shadow: 3px 3px 2px black;
    font-size: calc(10px + 2vw);
}

.pokemonApp-Container p {
    margin: auto;
    padding-left: 50px;
    color: white;
}

#pokeTooltipDropdown {
    position: absolute;
    text-align: left;
    background-color: transparent;
    width: 60px;
    height: 60px;
    color: black;
    filter: invert(1);
    margin-left: 20px;
    margin-top: 20px;
}

#pokeTooltipDropdown-content {
    position: relative;
    visibility: hidden;
    background-color: #cfccc5;
    border-radius: 10px;
    padding: 10px;
    z-index: 1;
    margin-left: 10px;
}

#pokeTooltipDropdown:hover #pokeTooltipDropdown-content{
    width: 360px;
    min-width: 360px;
    visibility: visible;
}

#pokeTooltipDropdown-content p {
    color: black;
}

@media screen and (max-width:800px) {
    .pokemonApp-Container{
        height: 100%;
    }
    
}

@media screen and (max-width:480px) {
    #pokeTooltipDropdown img {
        width: 30px;
    }
    
}