#project-container {
    background-color: rgb(41, 41, 41);
    color: white;
    text-align: center;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#project-container h1 {
    padding: 20px;
    margin-bottom: 0;
}

.project-flex-container {
    padding: 30px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.project {
    width: 250px;
    margin: 10px 30px 10px 30px;
}

.proj-container-overlay {
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.4s ease;
    background-color: brown;
    justify-content: center;
    
}

.project-title {
    color: white;
    font-size: 32px;
    font-weight: bolder;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;

}

.proj-img-container {
    position: relative;
    width: 100%;
}

.project-image {
    display: block;
    width: 100%;
    box-shadow: 10px 10px 10px black;

}

.proj-img-container:hover .proj-container-overlay {
    opacity: 1;
}

.project-button-try {
    float: left;
    margin: 10px;
    margin-right: 0px;
    margin-left: 0px;
    width: calc(50%);
    height: 60px;
    font-size: larger;
    font-weight: bold;
    background-color: rgb(89, 150, 89);
    color: white;
    display: inline;
    text-decoration: none;
    border: solid;
    border-color: white;
    box-shadow: 10px 10px 10px black;

}

.project-button-source {
    float: left;
    margin: 10px;
    margin-right: 0px;
    margin-left: 0px;
    height: 60px;
    width: calc(50%);
    font-size: larger;
    font-weight: bold;
    background-color: rgb(84, 121, 175);
    color: white;
    display: inline;
    text-decoration: none;
    border: solid;
    border-left: none;
    border-color: white;
    box-shadow: 10px 10px 10px black;

}

.project-button-try:hover {
    
    background-color: rgb(24, 129, 50);

}

.project-button-source:hover {
    
    background-color: rgb(23, 53, 153);

}

.project-button-source img{
    display: inline-block;
    width: 45px;
    padding: 5px 10px 10px 0px;
}

.project-button-source a{
    color: white;
    text-decoration: none;
}

.preoject-button-source a:visited{
    color: white;
    text-decoration: none;
}