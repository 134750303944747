@import './Animations.css';

.aboutme-container {
    min-height: 100vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    align-content: center;
    padding-bottom: 40px;
}

.aboutme-container p {
    max-width: 900px;
    margin: auto;
}

#aboutme-header {
    color:white;
    background-color: rgb(41, 41, 41);
}

#skills-header {
    padding-top: 30px;
}

.skill-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

.skill-container ul {
    display: flex;
    flex-direction: column;
}

.skill {
    background-color: rgb(34, 34, 34);
    color: white;
    list-style: none;
    padding: 20px;
    width: 150px;
}

.skill:hover {
    background-color: rgb(75, 75, 75);
}

#aboutme-body img {
    margin: 10px;
    box-shadow: 2px 2px 2px #000000;
}

#aboutme-body img:hover {
    background-color: rgba(200, 200, 200, 0.1);

}

.skill-dropdown{
    display: inline-block;
}

@media only screen and (min-width: 480px){
    #aboutme-container h1 {
        animation-name: slide-in-up, fade-in;
        animation-duration: 1s;
    }

    #aboutme-container h5 {
        animation-name: slide-in-up, fade-in;
        animation-duration: 1s;
    }

    #aboutme-body h4{
        animation-name: slide-in-up, fade-in;
        animation-duration: 1s;
    }
    
}

