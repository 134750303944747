@keyframes slide-in-right{
    from {
        transform: translateX(-20%);
        
    }
    
    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slide-in-left{
    from {
        transform: translateX(20%);
    }
    
    to {
        transform: translateX(0%);
    }
}

@keyframes slide-in-down {
    from {
        transform: translateY(-20%);
    }
    to {
        transform: translateY(0%);
    }
    
}

@keyframes slide-in-up {
    from {
        transform: translateY(20%);
    }
    to {
        transform: translateY(0%);
    }
    
}

@keyframes fade-in {
    from{
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
